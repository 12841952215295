.mapboxgl-ctrl-top-right {
  position: fixed;
  width: 60%;
  left: 20%;
  right: 20%;
  top: auto;
  bottom: 150px;
  float: none;
  z-index: 9999;
}

.mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapboxgl-ctrl-geocoder {
  max-width: none;
  float: none;
  margin: auto;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-top-right {
  display: none;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
}

.MuiTab-wrapper {
  margin-top: -10px
}

.MuiTab-wrapper > svg {
  top: 10px;
  position: relative;
}
